import React from 'react'

import SEO from '../components/seo'

import { BasicPageContain, CopyBlockStyle, Grid, GridRight } from '../utils/copperTypography';


const PrivacyPolicyPage = () => (
  <BasicPageContain>
    <SEO title="Privacy Policy" />
    <CopyBlockStyle>
      <h3>Copper Compression</h3>
      <h1>Privacy Policy</h1>
      <Grid>
        <GridRight>
          <h3>Privacy Policy</h3>
          <p className="wide">Your privacy is very important to us.</p>
          <p className="wide">We strive to offer our visitors the many advantages of Internet technology and to provide an interactive and personalized experience. We will never sell, barter, or rent your email address to any unauthorized third party. Period.” We have created this Privacy Policy to demonstrate our firm commitment to privacy and security. We collect information in different ways from visitors who access the various parts of our services and the network of Web sites accessible through our Service. We use this information primarily to provide a customized experience as you use our Products and Services.</p>
          <p className="wide">Like most Web sites, we also collect information automatically and through the use of electronic tools that may be transparent to our visitors. For example, we may log the name of your Internet Service Provider or use cookie technology to recognize you and hold information from your visit. As we adopt additional technology, we may also gather information through other means. In certain cases, you can choose not to provide us with information, for example by setting your browser to refuse to accept cookies.</p>


<p className="wide">What we do with the information we collect</p>

<p className="wide">Like other Web publishers, we collect information to enhance your visit and deliver more individualized content. We respect your privacy and do not share your information. Aggregated Information (information that does not personally identify you) may be used in many ways. For example, we may combine information about your usage patterns with similar information obtained from other users to help enhance our site and services (e.g., to learn which pages are visited most or what features are most attractive).</p>


<p className="wide">Governing Law</p>
  
  <p className="wide">This policy and the use of this Site are governed by the state in which we are primarily located. If a dispute arises under this Policy we agree to first try to resolve it with the help of a mutually agreed-upon mediator in the city and county in which we are primarily located. Any costs and fees other than attorney fees associated with the mediation will be shared equally by each of us. If it proves impossible to arrive at a mutually satisfactory solution through mediation, we agree to submit the dispute to binding arbitration in the city and county in which we are primarily located, under the rules of the American Arbitration Association. Judgment upon the award rendered by the arbitration may be entered in any court with jurisdiction to do so. This statement and the policies outlined herein are not intended to and do not create any contractual or other legal rights in or on behalf of any party.</p>


<p className="wide">Our Company Commitment to Children’s Privacy</p>
<p className="wide">Protecting children’s privacy is especially important to us. It is our policy to comply with the Children’s Online Privacy Protection Act of 1998 and all other applicable laws. Therefore we restrict our Web site to persons eighteen years or older.</p>
<p className="wide">YOU MUST BE EIGHTEEN (18) YEARS OR OLDER TO ACCESS THIS WEB SITE. IF YOU ARE UNDER EIGHTEEN YEARS OF AGE, YOU ARE NOT PERMITTED TO ACCESS THIS WEB SITE FOR ANY REASON. DUE TO THE AGE RESTRICTIONS FOR USE OF THIS WEB SITE, NO INFORMATION OBTAINED BY THIS WEB SITE, FALLS WITHIN THE CHILD ONLINE PRIVACY ACT (COPA) AND IS NOT MONITORED AS DOING SO.</p>


<p className="wide">Our Company’s Commitment to Data Security</p>
  
<p className="wide">Services and Web sites we sponsor have security measures in place to protect the loss, misuse, and alteration of the information under our control. While we make every effort to ensure the integrity and security of our network and systems, we cannot guarantee that our security measures will prevent third-party “hackers” from illegally obtaining this information.</p>

<p className="wide">Where to Direct Questions About Our Privacy Policy</p>

<p className="wide">If you have any questions about this Privacy Policy or the practices described herein, you may contact us through the contact information provided on this Web site.</p>

<p className="wide">Revisions to This Policy</p>

<p className="wide">Our company reserves the right to revise, amend, or modify this policy, our Terms Of Service agreement, and our other policies and agreements at any time and in any manner, by updating this posting.</p>


        </GridRight>
      </Grid>
    </CopyBlockStyle>
  </BasicPageContain>
)

export default PrivacyPolicyPage
